import React from 'react'
import Icon from './Icons'
import { revisitConsent } from '../../utils/cookieHelpers'

export function ContentBlocked(props) {
  const {
    width,
    height,
    blockedContentName,
    necessaryCookiesType,
    hideIcon,
  } = props

  function handleRevisitConsent() {
    revisitConsent()
  }

  return (
  // <div className={`${additionalClasses}`}>
    <div className='contentBlocked' style={{ width: `${width}px`, height: `${height}px` }}>
      <div className='contentBlocked-header'>
        Pardon the interruption...
      </div>
      {!hideIcon && (
        <div className='contentBlocked-icon'>
          <Icon name='empty-box' />
        </div>
      )}
      <div className='contentBlocked-message'>
        The use of
        {' '}
        {blockedContentName}
        {' '}
        requires additional cookies consent.
      </div>
      <div className='contentBlocked-message'>
        Please enable the &apos;
        {necessaryCookiesType}
        &apos; option in the <a href='#' onClick={handleRevisitConsent}>cookies manager</a>.
        {/* <div className='contentBlocked-message' data-cky-tag='revisit-consent'>
            CLICK HERE TO MANAGE YOUR CONSENT
          </div> */}
      </div>
    </div>
  // </div>
  )
}

export function ContentBlockedSimpleNotification(props) {
  const {
    blockedContentName,
    necessaryCookiesType,
  } = props
  return (
    <div className='contentBlocked contentBlocked-simpleNotification'>
      <div className='contentBlocked-header-small'>
        Pardon the interruption...
      </div>
      <div className='contentBlocked-message-small'>
        The use of
        {' '}
        {blockedContentName}
        {' '}
        requires additional cookies consent.
      </div>
      <div className='contentBlocked-message-small'>
        Please enable the &apos;
        {necessaryCookiesType}
        &apos; option in the cookies manager.
        {/* <div className='contentBlocked-message' data-cky-tag='revisit-consent'>
          CLICK HERE TO MANAGE YOUR CONSENT
        </div> */}
      </div>
    </div>
  )
}

export function YoutubeBlocked(props) {
  const {
    designModifierClassName,
    simpleNotification,
  } = props;

  function handleRevisitConsent() {
    revisitConsent()
  }

  const youtubeCookieCategoryName = 'Youtube Cookies';

  return (
    simpleNotification ? (
      <div className={`youtubeBlocked-container ${designModifierClassName || ''}`}>
        <div className={`youtubeBlocked-content ${designModifierClassName || ''}`}>
          <div className={`youtubeBlocked-icons ${designModifierClassName || ''}`}>
            <Icon name='video' />
          </div>
          <div className={`youtubeBlocked-text ${designModifierClassName || ''}`}>
            <p>
              To display video, please enable the '{youtubeCookieCategoryName}' <a href='#' onClick={handleRevisitConsent}>here</a>.
            </p>
            </div>
        </div>
      </div>
    ) : (
      <div className={`youtubeBlocked-container ${designModifierClassName || ''}`}>
        <div className={`youtubeBlocked-content ${designModifierClassName || ''}`}>
          <div className={`youtubeBlocked-icons ${designModifierClassName || ''}`}>
            <Icon name='cookie' />
            <div className={`youtubeImageIcon ${designModifierClassName || ''}`}></div>
          </div>
          <div className={`youtubeBlocked-text ${designModifierClassName || ''}`}>
            <p >
              We're unable to display Youtube due to your current privacy preferences.
            </p>
          </div>
          <div className={`youtubeBlocked-text ${designModifierClassName || ''}`}>
            <p>
              To make use of video, please enable the '{youtubeCookieCategoryName}' option in the <a href='#' onClick={handleRevisitConsent}>cookies manager</a>.
            </p>
            </div>
        </div>
      </div>
    )
  );
};
