import React from 'react'
import { Field, reduxForm } from 'redux-form'
import Icon from '../misc/Icons'
import { ensureRecaptcha } from './recaptchaUtil'

let captchaIdForUserCreation
let captchaIdForSessionCreation

/// ////// Validations//////////////

const requiredFirstName = (value) => (value ? undefined : 'Please enter your first name')
const requiredLastName = (value) => (value ? undefined : 'Please enter your last name')
const requiredEmail = (value) => (value ? undefined : 'Please enter an email address')
const requiredPassword = (value) => (value ? undefined : 'Please enter a password')

const minCharacters = (value) => (value && value.length < 6 ? 'Your password must be at least 6 characters long' : undefined)

const validEmailAddress = (value) => (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z0-9.-]/i.test(value) ?
  "This doesn't look like a valid email address." : undefined)

class SignUpFormInputField extends React.Component {
  constructor() {
    super()
    this.state = {
      showPassword: false,
    }
  }

  render() {
    const {
      input, styleName, autocomplete, placeholder, type, meta: { touched, error },
    } = this.props

    return (
      <div
        className={`signup-form-field${
          styleName ? ` signup-form-field--${styleName} ` : ''
        }${input.value !== '' ? ' signup-form-field--nonEmpty ' : ' signup-form-field--empty'
        }${touched && error ? ' signup-form-field--hasError ' : ''}`}
      >

        {styleName !== 'password' && (
        /* eslint-disable react/jsx-props-no-spreading */
        <input
          className='signup-form-field-input'
          {...input}
          placeholder={placeholder}
          type={type}
          autoComplete={autocomplete}
        />
        /* eslint-enable react/jsx-props-no-spreading */
        )}

        {styleName === 'password' && (
        /* eslint-disable react/jsx-props-no-spreading */
        <React.Fragment>
          <input
            className='signup-form-field-input'
            {...input}
            autoComplete={autocomplete}
            placeholder={placeholder}
            type={this.state.showPassword ? 'text' : 'password'}
          />

          <div
            className='signup-form-field--password-showPassword'
            onClick={() => { this.setState((prevState) => ({ showPassword: !prevState.showPassword })) }}
          >
            {this.state.showPassword &&
            <span>Hide</span>}
            {!this.state.showPassword &&
            <span>Show</span>}
          </div>
        </React.Fragment>
        /* eslint-enable react/jsx-props-no-spreading */
        )}

        {touched && error && (
        <div className='signup-form-field-errorIcon'>
          <Icon name='alert' />
        </div>
        )}

        {touched && error && (
        <div className='signup-form-field-errorText'>
          {error}
        </div>
        )}

      </div>
    )
  }
}

class SignupForm extends React.Component {
  constructor() {
    super()
    this.actuallySubmit = this.actuallySubmit.bind(this)
    this.preSubmit = this.preSubmit.bind(this)
    this.reloadCaptcha = this.reloadCaptcha.bind(this)
  }

  componentDidMount() {
    this.reloadCaptcha()
  }

  actuallySubmit(formProps) {
    const signUpData = {
      ...formProps,
      recaptchaResponse: window.grecaptcha.getResponse(captchaIdForUserCreation),
    }
    this.props.onSubmit(signUpData, window.grecaptcha.getResponse(captchaIdForSessionCreation))
    window.grecaptcha.reset(captchaIdForUserCreation)
    window.grecaptcha.reset(captchaIdForSessionCreation)
  }

  reloadCaptcha(callback) {
    const { handleSubmit, onRecaptchaError } = this.props
    ensureRecaptcha(
      () => {
        captchaIdForUserCreation = window.grecaptcha.render(
          this.recaptchaDivForUserCreation,
          {
            sitekey: process.env.REACT_APP_PLICKERS_RECAPTCHA_SITE_KEY,
            tabIndex: 100,
            size: 'invisible',
            callback: () => {
              window.grecaptcha.execute(captchaIdForSessionCreation)
            },
          },
        )
        captchaIdForSessionCreation = window.grecaptcha.render(
          this.recaptchaDivForSessionCreation,
          {
            sitekey: process.env.REACT_APP_PLICKERS_RECAPTCHA_SITE_KEY,
            tabIndex: 100,
            size: 'invisible',
            callback: handleSubmit(this.actuallySubmit),
          },
        )
        if (callback) callback()
      },
      onRecaptchaError,
    )
  }

  preSubmit() {
    this.props.onPreSubmit()
    if (
      !window.grecaptcha ||
      captchaIdForUserCreation === undefined ||
      captchaIdForSessionCreation === undefined
    ) {
      this.reloadCaptcha(this.preSubmit)
    } else {
      window.grecaptcha.execute(captchaIdForUserCreation)
    }
  }

  render() {
    const { handleSubmit, submitLoading } = this.props

    return (
      <form
        className={`signup-form ${
          this.props.anyTouched ? ' signup-form--anyTouched ' : ''
        }${this.props.valid ? ' signup-form--valid ' : ' signup-form--invalid'}`}
        onSubmit={handleSubmit(this.preSubmit)}
      >
        <div className='signup-form-nameContainer'>
          <Field
            name='firstName'
            styleName='firstName'
            type='text'
            component={SignUpFormInputField}
            validate={requiredFirstName}
            autocomplete='given-name'
            placeholder='First Name'
          />
          <Field
            name='lastName'
            styleName='lastName'
            type='text'
            component={SignUpFormInputField}
            validate={requiredLastName}
            autocomplete='family-name'
            placeholder='Last Name'
          />
        </div>

        <Field
          name='email'
          type='email'
          styleName='email'
          component={SignUpFormInputField}
          validate={[requiredEmail, validEmailAddress]}
          autocomplete='email'
          placeholder='you@example.com'
        />

        <Field
          name='password'
          type='password'
          styleName='password'
          component={SignUpFormInputField}
          validate={[requiredPassword, minCharacters]}
          placeholder='Password'
          autocomplete='new-password'
        />

        <button className='signup-form-submitBtn' type='submit'>

          {!submitLoading && (
          <React.Fragment>
            Continue with Email
          </React.Fragment>
          )}

          {submitLoading && (
          <div className='signup-form-submitBtn-loadingSpinnerContainer'>
            <div className='signup-form-submitBtn-loadingSpinner' />
          </div>
          )}

        </button>

        {/* eslint-disable no-return-assign */}
        <div ref={(div) => this.recaptchaDivForUserCreation = div} />
        <div ref={(div) => this.recaptchaDivForSessionCreation = div} />
        {/* eslint-enable no-return-assign */}
      </form>
    )
  }
}

export default reduxForm({
  form: 'signupForm',
})(SignupForm)
