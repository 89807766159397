import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { hideModal } from '../../actions/modals'

function CookiePolicyContainer() {
  const [cookiePolicyContent, setCookiePolicyContent] = useState('')

  useEffect(() => {
    const policyDiv = document.getElementById('ot-sdk-cookie-policy')

    if (policyDiv) {
      const policyContent = policyDiv.innerHTML
      setCookiePolicyContent(policyContent)
    }
  }, [])

  return (
    <div className='cookiePolicy-container'>
      <div className='cookiePolicy-content'>
        <h1>Plickers Cookie Policy</h1>
        <p>At Plickers, we are committed to protecting the privacy and personal data of our users in line with the General Data Protection Regulation (GDPR) and the California Consumer Privacy Act (CCPA). This Cookie Policy outlines how we use cookies on our website, the types of cookies we use, and how users can manage their cookie preferences.</p>

        <h2>1. What are Cookies?</h2>
        <p>Cookies are small text files that are stored on your device when you visit a website. These files allow websites to recognize your device, track your actions on the site, and store preferences to improve your browsing experience.</p>

        <h2>2. Types of Cookies We Use</h2>
        <p>Plickers uses different types of cookies, each serving a specific purpose. We use essential cookies that are necessary for our website to function properly, including those that enable secure login and load-balancing to ensure our website runs smoothly. We also use functional cookies to provide certain features (such as Youtube videos).</p>

        <h2>3. Your Rights Under GDPR and CCPA</h2>
        <p>As a user, GDPR grants you the right to access, modify, and delete any personal data collected via cookies, as well as the right to withdraw consent or object to the use of cookies at any time. Under CCPA, California residents have the right to opt-out of the sale or sharing of personal data collected through cookies. We ensure that users can easily manage their cookie preferences through our website. We also offer clear options to opt-out of non-essential cookies, such as functional cookies. This is further explained below.</p>

        <h2>4. Managing Your Cookie Preferences</h2>
        <p>Users can manage their cookie preferences at any time by accessing the preference center through our website. You can also control how you interact with cookies within your browser settings, where you can choose to block or delete cookies. Please note that disabling certain cookies may affect the functionality of the Plickers website and limit your ability to use some features.</p>
        <p>Different browsers provide different methods to block and delete cookies used by websites. You can change the settings of your browser to block/delete the cookies. Listed below are the links to the support documents on how to manage and delete cookies from the major web browsers.</p>
        <ul className='cookiePolicy-browserList'>
          <li><a href='https://support.google.com/accounts/answer/32050'>Chrome</a></li>
          <li><a href='https://support.apple.com/en-in/guide/safari/sfri11471/mac'>Safari</a></li>
          <li><a href='https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox?redirectslug=delete-cookies-remove-info-websites-stored&redirectlocale=en-US'>Firefox</a></li>
          <li><a href='https://support.microsoft.com/en-us/topic/how-to-delete-cookie-files-in-internet-explorer-bca9446f-d873-78de-77ba-d42645fa52fc'>Internet Explorer</a></li>
        </ul>

        <p>If you are using any other web browser, please visit your browser’s official support documents for more information.</p>

        <h2>5. Updates to Our Cookie Policy</h2>
        <p>Plickers may update this Cookie Policy from time to time to reflect changes in legal requirements or the way we use cookies. We encourage you to review this policy periodically to stay informed about how we are protecting your personal data. If we make significant changes to this policy, we will notify users through our website or via email. Your continued use of our website after any updates will constitute acceptance of those changes.</p>

        <h2>6. Cookie List</h2>

        <div className='cookiePolicy-generatedList' dangerouslySetInnerHTML={{ __html: cookiePolicyContent }} />
        <p>&nbsp;</p>
      </div>
    </div>
  )
}

export default connect(
  () => ({
  }),
  {
    hideModal,
  },
)(CookiePolicyContainer)
