import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import FormFieldText from '../../uiKit/FormFieldText'
import Button from '../../uiKit/Button'
import { getPasswordErrorMessage } from '../../../utils/passwordValidator'

class AddPasswordForm extends React.Component {
  render() {
    const { addPassword, submitLoading } = this.props
    return (
      <form onSubmit={addPassword} autoComplete='off'>

        <Field
          name='email'
          type='text'
          label='Email'
          component={FormFieldText}
          disabled
          size='large'
        />

        <Field
          name='newPassword'
          type='password'
          label='Password'
          component={FormFieldText}
          autoFocus
          validate={[getPasswordErrorMessage]}
          size='large'
          subLabel='Choose a password. Your password must be at least 6 characters.'
        />

        <div className='accountForm-actionRow'>
          <Button
            size='xLarge'
            label='Add password'
            color={this.props.dirty && this.props.valid ? 'blue' : 'gray'}
            canSubmit={!!(this.props.dirty && this.props.valid)}
            submitLoading={submitLoading}
            type='submit'
          />
        </div>
      </form>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  initialValues: {
    email: ownProps.email,
  },
})

export default connect(mapStateToProps)(reduxForm({
  form: 'addPasswordForm',
  enableReinitialize: true,
  autoComplete: 'off',
})(AddPasswordForm))
