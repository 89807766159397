import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import DocumentTitle from 'react-document-title'
import { connect } from 'react-redux'
import qs from 'qs'
import { resetPassword } from '../../actions/user'
import ResetPasswordForm from '../../components/forms/account/ResetPasswordForm'
import getErrorMessageForRequest from '../../utils/getErrorMessageForRequest'

class ResetPasswordContainer extends Component {
  constructor(props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
    const urlParams = qs.parse(window.location.search.slice(1))
    this.state = {
      errorMessage: null,
      submitLoading: false,
      user: urlParams.user,
      token: urlParams.token,
      passwordUpdated: false,
    }
  }

  handleSubmit(data) {
    const { token, user } = this.state
    const { resetPassword } = this.props

    this.setState({ submitLoading: true })
    const requestData = {
      password: data.password,
      token,
    }
    resetPassword(user, requestData)
      .then(() => {
        this.setState({ submitLoading: false, passwordUpdated: true })
      })
      .catch((error) => {
        this.setState({ submitLoading: false })
        const errorMessage = getErrorMessageForRequest({
          requestName: 'resetPassword',
          response: error.response,
        })
        this.setState({ errorMessage })
      })
  }

  render() {
    const {
      errorMessage,
      submitLoading,
      passwordUpdated,
    } = this.state
    return (
      <DocumentTitle title='Reset Password - Plickers'>
        <div className='resetPassword marketing'>
          <div
            className='resetPassword-backtoPlickersLogin'
            // if logged in, this takes user to 'library'
            // otherwise, to 'login'
            onClick={() => { this.props.history.push('') }}
          >
            Back to Plickers.com
          </div>
          <div className='resetPassword-centerBlock'>
            <div className='resetPassword-centerBlock--top' />
            <div className='resetPassword-centerBlock--middle'>
              <div className='resetPassword-centerBlock-title'>
                Reset Password
              </div>
              {!passwordUpdated && (
              <React.Fragment>
                <div className='resetPassword-centerBlock-subtitle'>
                  Your new Plickers password should be at least six characters.
                </div>
                <ResetPasswordForm
                  errorMessage={errorMessage}
                  onPreSubmit={this.onPreSubmit}
                  onSubmit={this.handleSubmit}
                  submitLoading={submitLoading}
                />
              </React.Fragment>
              )}
              {passwordUpdated && (
              <div className='resetPassword-centerBlock-subtitle'>
                Your Plickers password is now updated.
              </div>
              )}
            </div>
          </div>
        </div>
      </DocumentTitle>
    )
  }
}

export default withRouter(connect(
  (/* state */) => ({}),
  { resetPassword },
)(ResetPasswordContainer))
