export function isPasswordValid(password) {
  return password && password.length > 5
}

export function getPasswordErrorMessage(value) {
  if (!value) return 'Sorry but we are going to need you to tell us that'
  return isPasswordValid(value) ?
    undefined :
    'Password too short'
}
