import React from 'react'
import ModalWrapper from './ModalWrapper'
import CookiePolicyContainer from '../../containers/modals/CookiePolicyContainer'

const CookiePolicyModal = (props) => (
  /* eslint-disable react/jsx-props-no-spreading */
  <ModalWrapper
    {...props}
    title='Cookie Policy'
    modalStyle='dark'
    closeBtn
    cancelBtn={false}
  >
    <CookiePolicyContainer {...props} />
  </ModalWrapper>
  /* eslint-enable react/jsx-props-no-spreading */
)

export default CookiePolicyModal
